<template>
  <section class="container-fluid bg-light h-100 mb-2">
    <div class="px-4 flex-column justify-content-center">
      <CountChart />
      <div class="col-12 mt-4">
        <ChartAllCandidat />
      </div>
      <div class="row mt-4">
        <div class="col-6">
          <ChartCommentaire />
        </div>
        <div class="col-6">
          <ChartOffre />
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-6">
          <ChartCandidatMk />
        </div>
        <div class="col-6">
          <ChartCandidatService />
        </div>
      </div>

      <div class="col-6 mt-4">
        <ChartCandidatOffre />
      </div>
    </div>
  </section>
</template>

<script>
import ChartAllCandidat from "../../components/admin/ChartAllCandidat.vue";
import ChartCandidatMk from "../../components/admin/ChartCandidatMk.vue";
import ChartCandidatService from "../../components/admin/ChartCandidatService.vue";
import ChartCandidatOffre from "../../components/admin/ChartCandidatOffre.vue";
import ChartOffre from "../../components/admin/ChartOffre.vue";
import CountChart from "../../components/admin/CountChart.vue";
import ChartCommentaire from "../../components/admin/ChartComment.vue";

export default {
  name: "Dashboard",
  components: {
    ChartAllCandidat,
    ChartCandidatMk,
    ChartCandidatService,
    ChartCandidatOffre,
    ChartOffre,
    CountChart,
    ChartCommentaire,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
section {
  // height: 100vh;
}
</style>
