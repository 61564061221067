import { BASE_URL } from "../configs";
import { axiosInstanceCollab } from "./config";

export const getCountAllCandidatPerYear = (year) => {
  return axiosInstanceCollab.get(`${BASE_URL}/countCandidatPerYear/${year}`);
};

export const getCountAllCandidatMk = (date) => {
  return axiosInstanceCollab.get(
    `${BASE_URL}/countCandidatMk/${date[0].toISOString()}/${date[1].toISOString()}`
  );
};

export const getCountAllCandidatService = (date) => {
  return axiosInstanceCollab.get(
    `${BASE_URL}/countCandidatService/${date[0].toISOString()}/${date[1].toISOString()}`
  );
};

export const getCountAllCandidatOffre = (date) => {
  return axiosInstanceCollab.get(
    `${BASE_URL}/countCandidatOffre/${date[0].toISOString()}/${date[1].toISOString()}`
  );
};

export const getCountAllOffrePerYear = (year) => {
  return axiosInstanceCollab.get(`${BASE_URL}/countOffrePerYear/${year}`);
};

export const getCountAllCommentPerYear = (year) => {
  return axiosInstanceCollab.get(`${BASE_URL}/countCommentPerYear/${year}`);
};

export const getCountVisiteur = () => {
  return axiosInstanceCollab.get(`${BASE_URL}/countVisiteur`);
};

export const getCountCollab = () => {
  return axiosInstanceCollab.get(`${BASE_URL}/countCollab`);
};

export const getCountNewsletter = () => {
  return axiosInstanceCollab.get(`${BASE_URL}/countCandidatNewsletter`);
};

export const getCountArticle = () => {
  return axiosInstanceCollab.get(`${BASE_URL}/countArticle`);
};

export const getCountCommentaire = () => {
  return axiosInstanceCollab.get(`${BASE_URL}/countCommentaire`);
};

export const getCountOffre = () => {
  return axiosInstanceCollab.get(`${BASE_URL}/countOffre`);
};
