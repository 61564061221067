<template>
  <div class="pt-4 row justify-content-md-center">
    <CardStat
      :nombre="visiteur"
      :title="'inscriptions'"
      :icon="'fa-solid fa-user-group'"
    />
    <CardStat
      :nombre="collab"
      :title="'collaborateurs'"
      :icon="'fa-solid fa-user-group'"
    />
    <CardStat
      :nombre="newsletter"
      :title="'abonnées'"
      :icon="'fa-solid fa-envelope-circle-check'"
    />
    <CardStat
      :nombre="article"
      :title="'articles'"
      :icon="'fa-solid fa-newspaper'"
    />
  </div>
</template>

<script>
import {
  getCountVisiteur,
  getCountNewsletter,
  getCountCollab,
  getCountArticle,
} from "../../api/dashboard";
import CardStat from "../../components/public/CardStat.vue";
export default {
  name: "CountChart",
  components: {
    CardStat,
  },
  data() {
    return {
      visiteur: 0,
      collab: 0,
      newsletter: 0,
      article: 0,
    };
  },
  mounted() {
    getCountVisiteur().then((result) => {
      this.visiteur = result.data[0].NbVisiteur;
    });
    getCountCollab().then((result) => {
      this.collab = result.data[0].NbCollaborateur;
    });
    getCountNewsletter().then((result) => {
      this.newsletter = result.data[0].NbCandidatNewsletter;
    });
    getCountArticle().then((result) => {
      this.article = result.data[0].NbArticle;
    });
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.count {
  font-size: 3em;
}

.icon {
  width: 3rem;
  height: 3rem;
}

.icon i {
  font-size: 2.25rem;
}

.icon-shape {
  display: inline-flex;
  padding: 12px;
  text-align: center;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.icon-shape i {
  font-size: 1.25rem;
}
</style>
