<template>
  <div class="card py-2">
    <div class="mt-2 text-center">Candidature</div>
    <apexchart
      class="center"
      type="area"
      height="325"
      width="95%"
      :options="chartOptions"
      :series="series"
    ></apexchart>
    <div class="center">
      <date-picker
        type="year"
        @change="filterYear"
        :placeholder="year"
        v-model="year"
      >
      </date-picker>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { getCountAllCandidatPerYear } from "../../api/dashboard";

export default {
  name: "ChartAllCandidat",
  components: {
    DatePicker,
  },
  data() {
    return {
      year: new Date().getFullYear().toString(),
      series: [],
      chartOptions: {
        chart: {
          type: "area",
          height: 350,
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        labels: [
          "Jan",
          "Fev",
          "Mar",
          "Avr",
          "Mai",
          "Juin",
          "Jui",
          "Août",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        // title: {
        //   text: "Product Trends by Month",
        //   align: "left",
        // },
        xaxis: {
          type: "month",
        },
        yaxis: {
          title: {
            text: "Candidats",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " candidats";
            },
          },
        },
        colors: ["#2ECC71", "#ff9500", "#0DCAF0"],
      },
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      getCountAllCandidatPerYear(this.year).then((result) => {
        this.series = result.data;
      });
    },
    filterYear(data) {
      this.year = new Date(data).getFullYear().toString();
      this.fetch();
    },
  },
};
</script>

<style scoped>
.center {
  display: flex;
  justify-content: center;
}
</style>
