<template>
  <div v-if="!loading" class="card py-2">
    <div class="mt-2 text-center">Candidature Offre</div>
    <apexchart
      type="polarArea"
      height="325"
      :options="chartOptions"
      :series="series"
    ></apexchart>
    <div class="mt-2 center">
      <date-picker
        v-model="date"
        @change="filterDate"
        type="date"
        range
        placeholder="Select date range"
      ></date-picker>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { getCountAllCandidatOffre } from "../../api/dashboard";
import { showStatus, colorStatus } from "../../utils/index";

export default {
  name: "ChartCandidatOffre",
  components: {
    DatePicker,
  },
  data() {
    return {
      loading: true,
      date: [
        new Date(new Date().getFullYear().toString(), 0, 1),
        new Date(new Date().getFullYear().toString(), 11, 31),
      ],
      series: [],
      chartOptions: {
        chart: {
          type: "polarArea",
        },
        stroke: {
          colors: ["#fff"],
        },
        labels: [],
        fill: {
          opacity: 0.8,
        },
        legend: {
          position: "bottom",
        },
        yaxis: {
          show: false,
        },
        plotOptions: {
          polarArea: {
            rings: {
              strokeWidth: 0.8,
            },
            spokes: {
              strokeWidth: 0.8,
            },
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        colors: [],
      },
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.loading = true;
      this.chartOptions.colors = [];
      getCountAllCandidatOffre(this.date).then((result) => {
        this.chartOptions.labels = result.data.map((a) =>
          this.showStatus(a.status_candidat_offre)
        );
        this.series = result.data.map((a) => a.total);
        this.chartOptions.labels.forEach((e) => {
          this.chartOptions.colors.push(this.colorStatus(e));
        });
        this.loading = false;
      });
    },
    filterDate(date) {
      this.date = date;
      this.fetch();
    },
    showStatus(text) {
      return showStatus(text);
    },
    colorStatus(text) {
      return colorStatus(text);
    },
  },
};
</script>

<style scoped>
.center {
  display: flex;
  justify-content: center;
}
</style>
