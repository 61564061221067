<template>
  <div class="col-xl-3 col-lg-6">
    <div class="card card-stats mb-4 mb-xl-0">
      <div class="card-body">
        <div class="row">
          <div class="col py-1 px-3">
            <h5 class="text-muted mb-0 pb-1">{{ title }}</h5>
            <span class="h2 font-weight-bold mb-0">
              <ICountUp :endVal="nombre"
            /></span>
          </div>
          <div class="col-auto">
            <div
              class="icon icon-shape bg-primary text-white rounded-circle shadow"
            >
              <i :class="icon"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ICountUp from "vue-countup-v2";

export default {
  name: "CountChart",
  props: {
    nombre: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
  components: {
    ICountUp,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.count {
  font-size: 3em;
}

.icon {
  width: 3rem;
  height: 3rem;
}

.icon i {
  font-size: 2.25rem;
}

.icon-shape {
  display: inline-flex;
  padding: 12px;
  text-align: center;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.icon-shape i {
  font-size: 1.25rem;
}
</style>
