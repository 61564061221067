<template>
  <div v-if="!loading" class="card py-2">
    <div class="mt-2 text-center">Offre d'emploi</div>
    <apexchart
      class="center"
      type="area"
      height="325"
      width="95%"
      :options="chartOptions"
      :series="series"
    ></apexchart>
    <div class="center">
      <date-picker
        type="year"
        @change="filterYear"
        :placeholder="year"
        v-model="year"
      >
      </date-picker>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { getCountAllOffrePerYear } from "../../api/dashboard";

export default {
  name: "ChartOffre",
  components: {
    DatePicker,
  },
  data() {
    return {
      loading: true,
      year: new Date().getFullYear().toString(),
      series: [
        {
          name: "Offre d'emploi",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          type: "area",
          height: 350,
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        // title: {
        //   text: "Statistique offre par an",
        //   align: "left",
        // },
        // subtitle: {
        //   text: "Price Movements",
        //   align: "left",
        // },
        labels: [
          "Jan",
          "Fev",
          "Mar",
          "Avr",
          "Mai",
          "Juin",
          "Jui",
          "Août",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        xaxis: {
          type: "month",
        },
        yaxis: {
          title: {
            text: "Nombres",
          },
        },
        legend: {
          horizontalAlign: "left",
        },
        colors: ["#FFCD03"],
      },
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.loading = true;
      getCountAllOffrePerYear(this.year).then((result) => {
        this.series[0].data = result.data;
        this.loading = false;
      });
    },
    filterYear(data) {
      this.year = new Date(data).getFullYear().toString();
      this.fetch();
    },
  },
};
</script>

<style scoped>
.center {
  display: flex;
  justify-content: center;
}
</style>
